@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn-uicons.flaticon.com/2.4.2/uicons-brands/css/uicons-brands.css');

body {
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px #63ff68 inset;
  border-radius: 50%;
  position: relative;
  animation: l6 1.5s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  top: 100%;
  box-shadow: inherit;
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
}
@keyframes l6 {
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #12c64b;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #0f9f3c;
  }

  .scrolling-text {
    width: 100%;
    overflow: hidden;
    font-size: 2rem;
    white-space: nowrap;
    position: relative;
}

.scrolling-text-content {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-text 15s linear infinite;
    white-space: nowrap;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;